
import { Component, Vue, Watch } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import {
  AroResponseCounts,
  AROViewModel,
  AroViewModelWrapper,
  Incident,
} from "models";
import CreateARO from "@/views/manage/ARO/Create.vue";
import UpdateARO from "@/views/manage/ARO/Update.vue";
import { UPSERT } from "@/enums/crud";
import {
  AROs,
  Documents,
  CRUDFunctionality,
  Downloader,
  Incidents,
} from "@/hooks";
import dayjs from "dayjs";
import AuthModule from "@/store/modules/Auth";
import IncidentModal from "@/views/manage/incidents/IncidentsModal.vue";
import logger from "@/plugins/logger";

@Component({
  components: {
    JsonExcel,
    CreateARO,
    UpdateARO,
    IncidentModal,
  },
  filters: {
    fileName: function (value: string) {
      if (value.length > 30) {
        const front = value.substring(0, 20);
        const end = value.substring(value.length - 10);
        return front + "..." + end;
      } else {
        return value;
      }
    },
  },
})
export default class ManageAros extends Vue {
  items: AROViewModel[] = [];
  itemsFiltered: AROViewModel[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "name";
  expanded = [];
  deleteModal = false;
  archiveModal = false;
  importModal = false;
  importFieldShow = false;
  importFile = "";
  importValid = false;
  valid = false;
  createItem = false;
  updateItem = false;
  manageItemName = "";
  manageItemId = 0;
  archiveReason = "";
  company = "";
  numOfAROs = 0;
  numOfSOs = 0;
  limitOfAROs = 0;
  limitOfSOs = 0;
  refresh = false;
  reportsToUser = [
    {
      name: "",
      value: 0,
    },
  ];
  userCounts: AroResponseCounts = {
    aroCount: 0,
    soCount: 0,
  };
  subscriptionModal = {
    aro: false,
    so: false,
  };
  limitedReachedContract = false;
  limitedReachedPayfast = false;
  get getUserLimit() {
    if (
      AuthModule.getUserOverview == null ||
      AuthModule.getUserOverview.securityFirm == null
    )
      return null;
    return {
      soLimit: AuthModule.getUserOverview.securityFirm.sgLimit,
      aroLimit: AuthModule.getUserOverview.securityFirm.aroLimit,
      userLimit: AuthModule.getUserOverview.securityFirm.userLimit,
    };
  }
  updateARORecord: AROViewModel | null = null;
  createARORecord = {
    type: " ", // 'add' | 'edit'
    field: {},
    reportsToUser: this.reportsToUser,
  };
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };
  UPSERT = UPSERT;
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "First Name", value: "name" },
    { text: "Last Name", value: "surname" },
    { text: "Email", value: "email" },
    { text: "ID No", value: "idNumber" },
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Employee No", value: "employeeNumber" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    "First Name": string;
    "Last Name": string;
    Email: string;
    "ID No": string;
    "ARO/SO": string;
    "Employee No": string;
    "PSIRA Reg No": string;
    "Contact No": string;
    "Physical Address": string;
    Team: any;
    "Reports To": string;
    Picture: string;
    Signature: string;
  }[] = [];
  expFields = {
    "First Name": "name",
    "Last Name": "surname",
    Email: " ",
    "ID No": "idNumber",
    "ARO/SO": "isSecurityGuard",
    "Employee No": "employeeNumber",
    "PSIRA Reg No": "psiraRegistrationNumber",
    "Contact No": "contactNumber",
    "Physical Address": "address",
    Team: "teamArmResponseOfficers.team",
    "Reports To": " ",
    Picture: " ",
    Signature: " ",
  };
  incidentsModal: {
    open: boolean;
    selectedItem: AROViewModel | null;
    type: "Ammo" | "Firearm" | "ARO";
  } = {
    open: false,
    selectedItem: null,
    type: "ARO",
  };

  async importARO(): Promise<void> {
    try {
      logger.log("File:", this.importFile);

      var formdata = new FormData();
      formdata.append("file", this.importFile);

      const res = await AROs.importARO(formdata);
      this.$notifCreator.createSuccessNotification(
        "ARO(s) Imported Successfully!"
      );
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.importFieldShow = false;
      this.importFile = "";
      this.importModal = false;
      this.loadItems();
    }
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    //logger.log("Name: ", name, has);
    return has;
  }

  @Watch("refresh", { immediate: true })
  modalDataChanged(): void {
    if (this.refresh == true) {
      this.loadItems();
    }
  }
  redirectToSubscription() {
    const url = process.env.VUE_APP_WEBSITE_SUBSCRIPTION;
    console.log("Sub url ", url);
    window.open(url, "_blank");
  }
  openEditItem(record: AROViewModel): void {
    // record.reportsToUser = await this.getSupervisor(); // Why ?
    this.updateARORecord = record;
    // this.modalData.type = "edit";
    // this.modalData.field = i;
    // this.refresh = false;
    // this.updateItem = true;
  }

  openAddItem(): void {
    // if (this.getUserLimit == null) {
    //   logger.error("No user limit");
    //   return;
    // }
    // if (this.getUserLimit.userLimit <= this.userCounts.usersCount) {
    //   logger.warn("You have reached max users");
    //   if (
    //     AuthModule.getUserOverview &&
    //     AuthModule.getUserOverview.securityFirm
    //   ) {
    //     if (AuthModule.getUserOverview.securityFirm.payFastPass == true) {
    //       this.limitedReachedContract = true;
    //     } else {
    //       if (
    //         AuthModule.getUserOverview.securityFirm.payFastSubscription != null
    //       ) {
    //         // console
    //         this.limitedReachedPayfast = true;
    //       } else {
    //         logger.error("No payPass or payfast???");
    //       }
    //     }
    //   }
    //   return;
    // } else {
    // this.createARORecord = {}};
    this.createARORecord.type = "add";
    this.createARORecord.field = {};
    this.getSupervisor(); // Why ?
    this.refresh = false;
    this.createItem = true;
    // }
  }

  openDeleteItem(i: AROViewModel): void {
    this.manageItemName = i.name + " " + i.surname;
    this.manageItemId = i.id ? i.id : 0; // TODO@CVD Fix this to work with an undefined field.
    this.deleteModal = true;
  }

  openArchiveItem(i: AROViewModel): void {
    this.manageItemName = i.name + " " + i.surname;
    this.manageItemId = i.id ? i.id : 0; // TODO@CVD Fix this to work with an undefined field.
    this.archiveModal = true;
  }

  async archiveItem(): Promise<void> {
    try {
      const res = await AROs.archiveARO(this.manageItemId, this.archiveReason);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.archiveModal = false;
      this.$notifCreator.createSuccessNotification("ARO Archived Succesfully.");
      this.loadItems();
    }
  }

  async deleteItem(): Promise<void> {
    try {
      const res = await AROs.deleteARO(this.manageItemId);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.$notifCreator.createSuccessNotification("ARO Deleted Succesfully.");
      this.loadItems();
    }
  }

  updateReportsFiltered(event: any) {
    this.itemsFiltered = event ? (event as any[]) : this.items;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.itemsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "First Name", dataKey: "First Name" },
        { header: "Last Name", dataKey: "Last Name" },
        { header: "Email", dataKey: "Email" },
        { header: "ID No", dataKey: "ID No" },
        { header: "ARO/SO", dataKey: "ARO/SO" },
        { header: "Employee No", dataKey: "Employee No" },
        { header: "PSIRA Reg No", dataKey: "PSIRA Reg No" },
        { header: "Contact No", dataKey: "Contact No" },
        { header: "Physical Address", dataKey: "Physical Address" },
        { header: "Team", dataKey: "Team" },
        { header: "Reports To", dataKey: "Reports To" },
        { header: "Picture", dataKey: "Picture" },
        { header: "Signature", dataKey: "Signature" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `AROs (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "First Name",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loadItems(): Promise<void> {
    try {
      this.loading = true;
      const res = await AROs.getAros();
      const response: AROViewModel[] = [];
      if (res) {
        res.arOs.forEach((elem: AROViewModel) => {
          response.push(elem);
        });
      }
      this.items = response;
      this.userCounts = res.totals;
      // this.items != null
      //   ? (this.numOfAROs = this.items[0].numberOfArmResponseOfficers)
      //   : "";
      // this.items != null
      //   ? (this.numOfSOs = this.items[0].numberOfSecurityGuards)
      //   : "";
      // this.items != null
      //   ? (this.limitOfAROs = this.items[0].totalAROLimit)
      //   : "";
      // this.items != null ? (this.limitOfSOs = this.items[0].totalSGLimit) : "";
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
    this.expReports = this.populateExportData(this.items);
  }

  async mounted(): Promise<void> {
    await this.loadItems();
  }

  /**
   * Reports to User
   */
  async getSupervisor(): Promise<any[]> {
    const reportsToUser: any[] = [];
    try {
      const res = await AROs.getSupervisor();
      this.reportsToUser = [];
      res.forEach((elem: any) =>
        this.reportsToUser.push({
          name: elem.name + " " + elem.surname,
          value: elem.id,
        })
      );
      this.createARORecord.reportsToUser = this.reportsToUser;
      return Promise.resolve(reportsToUser);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  populateExportData(items: AROViewModel[]) {
    return items.map((elem: AROViewModel) => {
      return {
        "First Name": elem.name != null ? elem.name : " ",
        "Last Name": elem.surname != null ? elem.surname : " ",
        Email: " ",
        "ID No": elem.idNumber != null ? elem.idNumber : " ",
        "ARO/SO":
          elem.isSecurityGuard != null
            ? elem.isSecurityGuard == true
              ? "SO"
              : "ARO"
            : " ",
        "Employee No": elem.employeeNumber != null ? elem.employeeNumber : " ",
        "PSIRA Reg No":
          elem.psiraRegistrationNumber != null
            ? elem.psiraRegistrationNumber
            : " ",
        "Contact No": elem.contactNumber != null ? elem.contactNumber : " ",
        "Physical Address": elem.address != null ? elem.address : " ",
        Team: elem.teamName != null ? elem.teamName : " ",
        "Reports To": " ",
        Picture: " ",
        Signature: " ",
      };
    });
  }

  async downloadwPath(i: any): Promise<void> {
    /* window.open(process.env.VUE_APP_API_BASE + "/" + i); */
    var ext = i.substr(i.lastIndexOf(".") + 1);
    var type = "image/jpeg";
    if (ext == "jpeg" || ext == "jpg") {
      type = "image/jpeg";
    } else if (ext == "pdf") {
      type = "application/pdf";
    } else if (ext == "png") {
      type = "image/png";
    }
    try {
      await Documents.getFile(i).then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: type,
          })
        );
        window.open(url);
        logger.log(response);
      });
    } catch (err) {
      const req = await require("@/assets/FileNotFound.png");
      const resO = await fetch(req);
      const img = await resO.blob();
      const url = URL.createObjectURL(img);
      window.open(url);
      this.$notifCreator.createErrorNotification("Can't find Image.");
      return Promise.reject(err);
    }
  }

  openIncidentsForARO(item: AROViewModel) {
    this.incidentsModal.selectedItem = item;
    this.incidentsModal.open = true;
  }
}
