var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"ma-0 pa-5",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('div',[_vm._v("Add New Certificate(s)")]),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.certExpanded = !_vm.certExpanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                rotate: _vm.certExpanded,
              }},[_vm._v("mdi-chevron-down")])],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.certExpanded),expression:"certExpanded"}]},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])]),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf,.png,.jpg","dense":"","multiple":"","show-size":"","prepend-icon":"","prepend-inner-icon":"$file","label":"Certificate(s)","truncate-length":"16","outlined":"","hide-details":"","disabled":_vm.shouldDisplayForRole('editDocument')},on:{"change":_vm.insertedFiles},model:{value:(_vm.newFiles),callback:function ($$v) {_vm.newFiles=$$v},expression:"newFiles"}})],1)],1)],1)],1)],1)],1),_vm._l((_vm.certificates),function(cert){return _c('v-col',{key:cert.id,attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('div',[_vm._v("Certificate")]),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){cert.expanded = !cert.expanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                rotate: cert.expanded,
              }},[_vm._v("mdi-chevron-down")])],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(cert.expanded),expression:"cert.expanded"}]},[(cert && cert.file != null)?_c('v-hover',{attrs:{"close-delay":"35"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"flat":""}},[(cert.file.type !== 'application/pdf')?_c('v-img',{attrs:{"contain":"","height":200,"min-width":100,"src":cert.preview},on:{"error":_vm.errorHandler}},[_c('v-expand-transition',[(hover == true)?_c('div',{staticClass:"d-flex justify-center transition-fast-in-fast-out imageOverlay text-h2 white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewFileInTab(cert.file)}}},[_vm._v("View Image")])],1):_vm._e()])],1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"100px"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewFileInTab(cert.file)}}},[_vm._v("View PDF")])],1)],1)]}}],null,true)}):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf,.png,.jpg","dense":"","show-size":"","prepend-icon":"","prepend-inner-icon":"$file","label":"Certificate","truncate-length":"16","outlined":"","hide-details":"","disabled":_vm.shouldDisplayForRole('editDocument')},on:{"change":function($event){$event != null
                      ? (_vm.statement.preview = _vm.URL.createObjectURL($event))
                      : (_vm.certificates = _vm.certificates.filter((e) => e != cert))}},model:{value:(cert.file),callback:function ($$v) {_vm.$set(cert, "file", $$v)},expression:"cert.file"}})],1)],1)],1)],1)],1)],1)})],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('div',[_vm._v("Add Statement(s) of Results")]),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.statementExpanded = !_vm.statementExpanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                rotate: _vm.statementExpanded,
              }},[_vm._v("mdi-chevron-down")])],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.statementExpanded),expression:"statementExpanded"}]},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])]),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"disabled":_vm.loading == true ||
                    _vm.shouldDisplayForRole('editDocument') == true,"accept":".pdf,.png,.jpg","dense":"","multiple":"","show-size":"","prepend-icon":"","prepend-inner-icon":"$file","label":"Statement of Results","truncate-length":"16","outlined":"","hide-details":""},on:{"change":_vm.insertedStatements},model:{value:(_vm.newStatements),callback:function ($$v) {_vm.newStatements=$$v},expression:"newStatements"}})],1)],1)],1)],1)],1)],1),_vm._l((_vm.statements),function(statement){return _c('v-col',{key:statement.id,attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('div',[_vm._v("Statement of Results")]),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){statement.expanded = !statement.expanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                rotate: statement.expanded,
              }},[_vm._v("mdi-chevron-down")])],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(statement.expanded),expression:"statement.expanded"}]},[(statement && statement.file != null)?_c('v-hover',{attrs:{"close-delay":"35"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"flat":""}},[(statement.file.type !== 'application/pdf')?_c('v-img',{attrs:{"contain":"","height":200,"src":statement.preview}},[_c('v-expand-transition',[(hover == true)?_c('div',{staticClass:"d-flex justify-center transition-fast-in-fast-out imageOverlay text-h2 white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewFileInTab(statement.file)}}},[_vm._v("View Image")])],1):_vm._e()])],1):_vm._e()],1)]}}],null,true)}):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"disabled":_vm.loading == true ||
                    _vm.shouldDisplayForRole('editDocument') == true,"accept":".pdf,.png,.jpg","dense":"","show-size":"","prepend-icon":"","prepend-inner-icon":"$file","label":"Statement of Results","truncate-length":"16","outlined":"","hide-details":""},on:{"change":function($event){$event != null
                      ? (statement.preview = _vm.URL.createObjectURL($event))
                      : (_vm.statements = _vm.statements.filter(
                          (e) => e != statement
                        ))}},model:{value:(statement.file),callback:function ($$v) {_vm.$set(statement, "file", $$v)},expression:"statement.file"}})],1)],1)],1)],1)],1)],1)})],2),_c('v-row',{staticClass:"mt-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5 text-left mb-0"},[_vm._v("Available options")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"auto","disabled":!_vm.getSAPSHandgun ||
          _vm.getBusHandgun ||
          _vm.shouldDisplayForRole('editDocument') == true,"label":`Handle and Use a Handgun for Business Purpose (U/S 123515)${
          _vm.getSAPSHandgun == false ? ' (requires SAPS HG)' : ''
        }${_vm.getBusHandgun == true ? ' (already exists)' : ''}`},model:{value:(_vm.currentDoc.BusinessHandgun),callback:function ($$v) {_vm.$set(_vm.currentDoc, "BusinessHandgun", $$v)},expression:"currentDoc.BusinessHandgun"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"auto","disabled":!_vm.getSAPSShotgun ||
          _vm.getBusShotgun ||
          _vm.shouldDisplayForRole('editDocument') == true,"label":`Handle and Use a Shotgun for Business Purpose (U/S 123514)${
          _vm.getSAPSShotgun == false ? ' (requires SAPS SG)' : ''
        }${_vm.getBusShotgun == true ? ' (already exists)' : ''}`},model:{value:(_vm.currentDoc.BusinessShotgun),callback:function ($$v) {_vm.$set(_vm.currentDoc, "BusinessShotgun", $$v)},expression:"currentDoc.BusinessShotgun"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"auto","disabled":!_vm.getSAPSSLR ||
          _vm.getBusSLR ||
          _vm.shouldDisplayForRole('editDocument') == true,"label":`Handle and Use a Self-Loading Rifle or Carbine for Business Purpose (U/S 123511)${
          _vm.getSAPSSLR == false ? ' (requires SAPS SLR)' : ''
        }${_vm.getBusSLR == true ? ' (already exists)' : ''}`},model:{value:(_vm.currentDoc.BusinessSelfloadingrifle),callback:function ($$v) {_vm.$set(_vm.currentDoc, "BusinessSelfloadingrifle", $$v)},expression:"currentDoc.BusinessSelfloadingrifle"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"auto","disabled":!_vm.getSAPSMLR ||
          _vm.getBusMLR ||
          _vm.shouldDisplayForRole('editDocument') == true,"label":`Handle and Use a Manually-Operated Rifle or Carbine for Business Purpose (U/S 123519)${
          _vm.getSAPSMLR == false ? ' (requires SAPS MOR)' : ''
        }${_vm.getBusMLR == true ? ' (already exists)' : ''}`},model:{value:(_vm.currentDoc.BusinessManuallyoperatedrifle),callback:function ($$v) {_vm.$set(_vm.currentDoc, "BusinessManuallyoperatedrifle", $$v)},expression:"currentDoc.BusinessManuallyoperatedrifle"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","block":"","disabled":(_vm.currentDoc.BusinessHandgun == false &&
            _vm.currentDoc.BusinessShotgun == false &&
            _vm.currentDoc.BusinessSelfloadingrifle == false &&
            _vm.currentDoc.BusinessManuallyoperatedrifle == false) ||
          _vm.statements.length == 0 ||
          _vm.certificates.length == 0 ||
          _vm.shouldDisplayForRole('editDocument') == true},on:{"click":_vm.addBPUS}},[_vm._v("Submit")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }