
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import {
  ArmResponseOfficer,
  Firearm,
  PsiraRegistrationDocuments,
  PsiraRegistrationDocuments_Create,
} from "models";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Docs from "@/store/modules/Documents";
import FileMixin from "@/mixins/FileMixin.vue";
import logger from "@/plugins/logger";

interface Certificate {
  armResponseOfficerID: number;
  certificateFilesPath: string;
  certificates: string[];
  certificatesPath: string[] | null;
  createdBy: string;
  createdDate: string;
  firearmType: string;
  id: number;
  isTacticalUnitStandards: boolean;
  statementOfResultFilesPath: string;
  statements: string[];
  statementsPath: string[];
  updatedBy: string;
  updatedDate: string;
}

@Component({
  components: {
    VuePdfApp,
  },
})
export default class TacticalUnitStandards extends Mixins(FileMixin) {
  @Prop() certificate!: Certificate | null;
  @Prop() id!: number;

  newFiles: File[] = [];
  newStatements: File[] = [];
  newFilesExpanded = false;
  newStatementsExpanded = false;

  currentDoc = {
    Handgun: false,
    Shotgun: false,
    Selfloadingrifle: false,
    IsTacticalUnitStandards: true,
    BasicProficiencyCertificateID: 0,
    BasicProficiencyAROID: 0,
  };

  loading = false;
  // handgun = false;
  // shotgun = false;
  // selfLoadingRifle = false;
  // manuallyOperatedRifle = false;
  URL = URL;
  certificates: {
    file: File | null;
    filePath: string;
    expanded: boolean;
    preview: string;
    id: number;
  }[] = [];
  statements: {
    file: File | null;
    filePath: string;
    expanded: boolean;
    preview: string;
    id: number;
  }[] = [];
  errorHandler(e: any) {
    //   logger.log("Erroror", e);
  }

  async deleteFile(certPath: string, statePath: string): Promise<void> {
    try {
      this.loading = true;
      const fileId = this.currentDoc.BasicProficiencyCertificateID;

      const res = await Documents.deleteBasicProficiency(
        fileId,
        statePath,
        certPath
      );

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.loading = false;
    }
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  insertedStatements(arr: File[]): void {
    //   logger.log("Testing inserting files trigger ", arr);
    if (arr.length > 0) {
      arr.forEach((f) => {
        const found = this.statements.findIndex((e) => e.file?.name == f.name);
        if (found == -1) {
          this.statements.push({
            expanded: false,
            preview: URL.createObjectURL(f),
            file: f,
            id: Math.random() * 100,
            filePath: "",
          });
        }
      });
      arr.splice(0);
    } else {
      this.statements.splice(0);
    }
  }
  get validForm() {
    return (
      this.statements.length > 0 &&
      this.certificates.length > 0 &&
      (this.currentDoc.Handgun == true ||
        this.currentDoc.Shotgun == true ||
        this.currentDoc.Selfloadingrifle == true)
    );
  }
  insertedFiles(arr: File[]): void {
    //   logger.log("Testing inserting files trigger ", arr);
    if (arr.length > 0) {
      arr.forEach((f) => {
        const found = this.certificates.findIndex(
          (e) => e.file?.name == f.name
        );
        if (found == -1) {
          this.certificates.push({
            expanded: false,
            preview: URL.createObjectURL(f),
            file: f,
            id: Math.random() * 100,
            filePath: "",
          });
        }
      });
      arr.splice(0);
    } else {
      this.certificates.splice(0);
    }
  }
  async initCerts(): Promise<void> {
    const temp: Promise<File | null>[] = [];
    if (this.certificate != null && this.certificate.certificatesPath) {
      //   logger.log("Init Certs ------------:");
      this.certificate.certificatesPath.forEach((element: string) => {
        //   logger.log("Init a cert :", element);
        const that = Documents.getFile(element);
        if (that != null) temp.push(that);
      });
      const certArr = await Promise.all(temp);
      //   logger.log("Certs : ", certArr);
      const zip = (a: string[], b: (File | null)[]) =>
        a.map((v: string, i: number) => {
          return {
            file: b[i],
            path: v,
          };
        });
      const data = zip(this.certificate.certificatesPath, certArr);
      logger.log("data", data);
      this.certificates.splice(0);
      data.forEach((cert: { file: File | null; path: string }) => {
        if (cert) {
          this.certificates.push({
            expanded: false,
            preview: URL.createObjectURL(cert.file),
            file: cert.file,
            id: Math.random() * 100,
            filePath: cert.path,
          });
        }
      });
    } else {
      this.certificates.splice(0);
    }

    return Promise.resolve();
  }
  async initStatements(): Promise<void> {
    const temp: Promise<File | null>[] = [];
    if (this.certificate != null) {
      this.certificate.statementsPath.forEach((element: string) => {
        //   logger.log("Running for ", element);
        const that = Documents.getFile(element);
        if (that != null) temp.push(that);
      });
      const certArr = await Promise.all(temp);
      //   logger.log("Statements : ", certArr);

      const zip = (a: string[], b: (File | null)[]) =>
        a.map((v: string, i: number) => {
          return {
            file: b[i],
            path: v,
          };
        });
      const data = zip(this.certificate.statementsPath, certArr);
      logger.log("data", data);
      this.statements.splice(0);
      data.forEach((cert) => {
        if (cert) {
          this.statements.push({
            expanded: false,
            preview: URL.createObjectURL(cert.file),
            file: cert.file,
            id: Math.random() * 100,
            filePath: cert.path,
          });
        }
      });
    } else {
      this.statements.splice(0);
    }

    return Promise.resolve();
  }
  get getAroDetails() {
    return Docs.getAroDocs;
  }
  async init(): Promise<void> {
    try {
      //   logger.log("Init docs in BasicPus");
      this.loading = true;
      if (this.getAroDetails != null) {
        if (this.certificate != null) {
          this.currentDoc.Handgun =
            this.certificate.firearmType.includes("Handgun");
          this.currentDoc.Shotgun =
            this.certificate.firearmType.includes("Shotgun");
          this.currentDoc.Selfloadingrifle =
            this.certificate.firearmType.includes("Self-Loading Rifle");
          this.currentDoc.IsTacticalUnitStandards = true;
          this.currentDoc.BasicProficiencyCertificateID = this.certificate.id;
          this.currentDoc.BasicProficiencyAROID =
            this.certificate.armResponseOfficerID;
        } else {
          this.currentDoc.Handgun = false;
          this.currentDoc.Shotgun = false;
          this.currentDoc.Selfloadingrifle = false;
          this.currentDoc.IsTacticalUnitStandards = true;
          this.currentDoc.BasicProficiencyCertificateID = 0;
          this.currentDoc.BasicProficiencyAROID = this.getAroDetails.id;
        }
        const temp = [this.initCerts(), this.initStatements()];
        const res = await Promise.all(temp);
        //   logger.log("Results :", res);
      }
      return Promise.resolve();
    } catch (err) {
      logger.error("BasicPus Init error: ", err);
    } finally {
      this.loading = false;
    }
  }
  get getAroDocs() {
    return Docs.getAroDocs;
  }
  @Watch("certificate", {
    immediate: true,
  })
  async onCertificateChanged(val: Certificate | null): Promise<void> {
    //   logger.log("Certificate changed ! ---", val);
    await this.init();
  }
  async mounted(): Promise<void> {
    await this.init();
  }
  get getBusHandgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter((e) =>
          e.firearmType.includes("Handgun")
        ).length != 0
      );
    } else return false;
  }

  get getBusShotgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter((e) =>
          e.firearmType.includes("Shotgun")
        ).length != 0
      );
    } else return false;
  }
  get getBusMLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter((e) =>
          e.firearmType.includes("Manually-Operated Rifle")
        ).length != 0
      );
    } else return false;
  }
  get getBusSLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter((e) =>
          e.firearmType.includes("Self-Loading Rifle")
        ).length != 0
      );
    } else return false;
  }
  get getHandgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.basicProficiencyCertificates.filter(
          (e) =>
            e != this.certificate &&
            e.isTacticalUnitStandards == true &&
            e.firearmType.includes("Handgun")
        ).length != 0
      );
    } else return false;
  }

  get getShotgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.basicProficiencyCertificates.filter(
          (e) =>
            e != this.certificate &&
            e.isTacticalUnitStandards == true &&
            e.firearmType.includes("Shotgun")
        ).length != 0
      );
    } else return false;
  }
  get getMLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.basicProficiencyCertificates.filter(
          (e) =>
            e != this.certificate &&
            e.isTacticalUnitStandards == true &&
            e.firearmType.includes("Manually-Operated Rifle")
        ).length != 0
      );
    } else return false;
  }
  get getSLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.basicProficiencyCertificates.filter(
          (e) =>
            e != this.certificate &&
            e.isTacticalUnitStandards == true &&
            e.firearmType.includes("Self-Loading Rifle")
        ).length != 0
      );
    } else return false;
  }
  async addTUS(): Promise<void> {
    try {
      this.loading = true;
      if (this.currentDoc) {
        const formData = new FormData();
        Object.entries(this.currentDoc).forEach(([key, value]) => {
          if (typeof value === "boolean" || typeof value === "number") {
            formData.append(key, String(value));
          } else {
            formData.append(key, value);
          }
        });
        this.certificates.forEach((element, index) => {
          if (element.file != null) {
            formData.append("CertificateFiles", element.file);
            // tempCert.push(element.file);
          }
        });
        this.statements.forEach((element) => {
          if (element.file != null) {
            formData.append("StatementOfResultFiles", element.file);
            // tempState.push(element.file);
          }
        });
        //   logger.log("Form data ", formData.entries());
        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addBasicProficiency",
          formData
        );
        this.$emit("refresh");
        //   logger.log("Add Basic PUS response", res.data);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  // @Watch("knowledgeOfAct", {
  //   immediate: true,
  // })
  // onKoaChanged(val: string | File | null, oldVal: string | File | null): void {
  ////   logger.log("Koa changed from ", oldVal, " to ", val);
  //   this.koaFile = val;
  // }
  // @Watch("statementOfResults", {
  //   immediate: true,
  // })
  // onSorChanged(val: string | File | null, oldVal: string | File | null): void {
  ////   logger.log("Sor changed from ", oldVal, " to ", val);
  //   this.sorFile = val;
  // }
}
