
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { ROLE as RoleEnum } from "@/enums/roles";
import { User } from "@/hooks";
import AuthModule from "@/store/modules/Auth";
import Signature from "@/components/Signature.vue";
import { Documents } from "@/hooks";
import { UserNameModel } from "@/models/username.model";
import { SuggestedUsernames } from "models";
import logger from "@/plugins/logger";

@Component({
  components: {
    Signature,
  },
})
export default class ManageUserModal extends Vue {
  @Prop() counts!: {
    usersCount: number;
  };
  @PropSync("open") manageItem!: boolean;
  @PropSync("refresh") refreshData!: boolean; // Tells parent that it should renew the list of items.
  @Prop() readonly data!: {
    type: string;
    field: any;
    // reportsToUser: [{ name: string; value: string }]; // Removed temporarily
  };
  valid = false;
  email = "";
  password = "";
  // lockOut = false;
  signature: string | null = null;
  clearSignaturePad = false;
  picture = null;
  redacted = true;
  roles = Object.entries(RoleEnum)
    .filter((item) => {
      return [
        "Security Firm Admin",
        "Security Firm Sub Admin",
        "Responsible Person",
        "Shift Supervisor",
        "Control Room",
      ].includes(item[0]);
    })
    .map((item) => {
      return { name: item[0], value: item[1] };
    });

  // Originally : DEPRECATED in favour of RolesEnum
  // roles = [
  //   { name: "Security Firm Admin", value: 2 },
  //   { name: "Security Firm Sub Admin", value: 3 },
  //   { name: "Responsible Person", value: 4 },
  //   { name: "Shift Supervisor", value: 5 },
  //   /* { name: "Armed Response Officer", value: 6 }, */
  //   { name: "Control Room", value: 7 },
  // ];
  get getLimits() {
    return AuthModule.getUserLimits;
  }
  setProxies(): void {
    switch (this.data.field.roleID) {
      case 2:
        this.data.field.assignFirearmProxy = true;
        this.data.field.incidentProxy = true;
        this.data.field.reportingProxy = true;
        break;
      case 3:
        this.data.field.assignFirearmProxy = false;
        this.data.field.incidentProxy = true;
        this.data.field.reportingProxy = true;
        break;
      case 4:
        this.data.field.assignFirearmProxy = true;
        this.data.field.incidentProxy = true;
        this.data.field.reportingProxy = true;
        break;
      case 5:
        this.data.field.assignFirearmProxy = true;
        this.data.field.incidentProxy = true;
        this.data.field.reportingProxy = true;
        break;
      case 7:
        this.data.field.assignFirearmProxy = true;
        this.data.field.incidentProxy = true;
        this.data.field.reportingProxy = true;
        break;
    }
  }

  async handleSignatureUpdate(b64: string): Promise<void> {
    logger.log("Signature: ", b64);
    if (b64 != undefined) {
      this.signature = b64;
    } else {
      this.$notifCreator.createErrorNotification(
        "Sorry! Something went wrong, Please try later"
      );
    }
  }

  closeModal(): void {
    this.password = "";
    this.email = "";
    this.signature = null;
    this.picture = null;
    // this.lockOut = false;
    this.clearSignaturePad = true;
    this.manageItem = false;
  }

  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
    id: (value: string): boolean | string =>
      (value && value.length == 13) || "Requires 13 digits",
    cell: (value: string): boolean | string =>
      (value && value.length == 10) || "Requires 10 digits",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };

  @Watch("data.field", { immediate: true })
  modalDataChanged(): void {
    logger.log("This modal field:", this.data.field);
  }

  async getFileFromb64(b64: string): Promise<File> {
    const filetype = Documents.detectMimeType(b64);
    const int8Array = await fetch(b64)
      .then((res) => res.arrayBuffer())
      .then((buffer) => new Uint8Array(buffer));
    const file = new File(
      [new Blob([int8Array], { type: filetype })],
      this.data.field.name,
      {
        type: filetype,
      }
    );
    return Promise.resolve(file);
  }

  async addItem(): Promise<void> {
    logger.log("Add user info: ", this.data.field);
    var formdata = new FormData();
    Object.keys(this.data.field).forEach((elem) => {
      formdata.append(
        elem == "lockoutEnabled" ? "lockOut" : elem,
        elem == "email" ? this.data.field[elem].trim() : this.data.field[elem]
      );
    });
    formdata.append("password", this.password);
    formdata.append(
      "customUserName",
      this.data.field.userName == undefined ? "" : this.data.field.userName
    );
    formdata.append(
      "lockOut",
      this.data.field.lockoutEnabled == undefined
        ? true
        : this.data.field.lockoutEnabled
    );
    formdata.append("picture", this.picture ? this.picture : "");
    formdata.append(
      "signature",
      this.signature ? await this.getFileFromb64(this.signature) : ""
    );
    try {
      const res = await User.addUser(formdata);
      this.$notifCreator.createSuccessNotification("User Added Succesfully!");
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      // (this.$refs.form as HTMLFormElement).reset();
      // const form = this.$refs["form"] as any;
      // logger.log(typeof form);
      // form.reset();
      this.closeModal();
    }
  }

  async editItem(): Promise<void> {
    logger.log("Edit User info: ", this.data.field);

    this.data.field.deleted_At = "";
    this.data.field.userPictureID = "";
    this.data.field.userSignatureID = "";
    this.data.field.reportToUserID == null
      ? ""
      : this.data.field.reportToUserID;

    var formdata = new FormData();
    Object.keys(this.data.field).forEach((elem) => {
      logger.log("Field Value : ", elem, this.data.field[elem], formdata);
      if (this.data.field[elem] != null) {
        formdata.append(
          elem == "lockoutEnabled" ? "lockOut" : elem, // Remaps "lockoutEnabled" to "lockOut"
          elem == "email" ? this.data.field[elem].trim() : this.data.field[elem]
        );
      }
    });
    formdata.append("password", this.password);
    // formdata.append("lockOut", this.data.field.lockoutEnabled); // Handled by the above loop
    formdata.append("picture", this.picture ? this.picture : "");
    formdata.append(
      "signature",
      this.signature ? await this.getFileFromb64(this.signature) : ""
    );

    try {
      const res = await User.editUser(formdata);
      this.$notifCreator.createSuccessNotification("User Edited Succesfully!");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.closeModal();
    }
  }
  usernameHint: string | null = null;
  async ConfirmUsernameExist(): Promise<SuggestedUsernames> {
    try {
      this.usernameHint = null;
      let u: UserNameModel = new UserNameModel();
      u.firstName = this.data.field.name;
      u.lastName = this.data.field.surname;
      u.username = this.data.field.userName;
      const username = await User.confirmUsernameExist(u);
      if (username.usernameExist) {
        username.sugestedUsername.forEach((element) => {
          this.usernameHint =
            this.usernameHint == null
              ? element
              : this.usernameHint + " " + element;
        });
        this.usernameHint =
          "This username already exist please try another or one of the suggested :" +
          this.usernameHint;
      } else {
        this.usernameHint = null;
      }
      return Promise.resolve(username);
    } catch (err) {
      this.usernameHint = "Error validating username";
      return Promise.reject(err);
    }
  }
}
