
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import {
  ArmResponseOfficer,
  Firearm,
  PsiraRegistrationDocuments,
  PsiraRegistrationDocuments_Create,
} from "models";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Docs from "@/store/modules/Documents";
import FileMixin from "@/mixins/FileMixin.vue";
import logger from "@/plugins/logger";

@Component({
  components: {
    VuePdfApp,
  },
})
export default class PsiraReg extends Mixins(FileMixin) {
  // @Prop() docs!: PsiraRegistrationDocuments[];
  // @Prop() id!: number;

  isOpenExpiryDate = false;
  isOpenRenewalProofDate = false;
  loading = false;
  psiraExpanded = false;
  psiraRenewalExpanded = false;

  psiraPreview: null | string = null;
  renewedPreview: null | string = null;
  get getCurrentDoc() {
    return this.currentDoc;
  }
  get getRenewalRules() {
    return [
      (v: string) => {
        if (v == null && this.getCurrentDoc != null) {
          if (this.getCurrentDoc.isRenewed == true) {
            return "Required";
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    ];
  }
  validate(): void {
    //   logger.log("Outside validate");
    if (this.$refs.form) {
      //   logger.log("Outside inside");
      (this.$refs.form as any).validate();
    }
  }
  URL = URL;
  currentDoc: PsiraRegistrationDocuments_Create | null = null;
  errorHandler(e: any) {
    //   logger.log("Erroror", e);
  }
  valid = false;
  // onFileChanged(event: File | null, field: "PSIRA" | "RENEWAL") {
  //   if (event && event != null) {
  //  //   logger.log("Event :", event);
  //     const file = event;
  //     if (!file) {
  //       return false;
  //     }
  //     if (!file.type.match("image.*")) {
  //       return false;
  //     }
  //     const reader = new FileReader();
  //  //   logger.log("File changed ", file);
  //     reader.onerror = (e) => {
  //    //   logger.log("On error ", e);
  //     };
  //     reader.onload = (e) => {
  //    //   logger.log("On load ", e);
  //       if (e.target != null && e.target.result != null) {
  //         if (field == "PSIRA") {
  //           this.psiraPreview = e.target.result as string;
  //         } else if (field == "RENEWAL") {
  //           this.renewedPreview = e.target.result as string;
  //         }
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     if (field == "PSIRA") {
  //       this.psiraPreview = null;
  //     } else if (field == "RENEWAL") {
  //       this.renewedPreview = null;
  //     }
  //   }
  // }
  async addReg(): Promise<void> {
    try {
      this.loading = true;
      if (this.getAroDocs != null) {
        const formData = new FormData();
        if (this.currentDoc != null) {
          Object.entries(this.currentDoc).forEach(([key, value]) => {
            if (value == null) {
              value = "";
            }
            if (key === "PSIRARegistrationAROID") {
              const temp = value == 0 ? this.getAroDocs?.id : value;
              formData.append(key, temp);
            } else {
              if (this.currentDoc?.isRenewed == false) {
                if (key !== "RenewedDocumentFile" && key !== "RenewedDate") {
                  //   formData.append(key, "");
                  // } else {
                  formData.append(key, value);
                }
              } else {
                formData.append(key, value);
              }
            }
          });
        }

        // if(this.currentDoc != null) {
        //   psiraFile.append("file" , new Blob(this.currentDoc.PSIRAFile))
        // }
        // ;
        //   logger.log("Form data ", formData);
        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addPSIRARegistration",
          formData
        );
        this.$emit("refresh");
        //   logger.log("Add Reg response", res.data);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async deleteFile(file: number): Promise<void> {
    try {
      this.loading = true;
      if (this.getAroDocs) {
        const fileId = this.getAroDocs.psiraRegistrationDocuments[0].id;

        const res = await Documents.deletePSIRARegistration(fileId, file);
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.loading = false;
    }
  }
  get getPsiraPreview() {
    // return this.psiraPreview;
    if (this.currentDoc != null && this.currentDoc.PSIRAFile != null)
      return URL.createObjectURL(this.currentDoc.PSIRAFile);
    else return "";
  }
  get getRenewedPreview() {
    // return this.psiraPreview;
    if (this.currentDoc != null && this.currentDoc.RenewedDocumentFile != null)
      return URL.createObjectURL(this.currentDoc.RenewedDocumentFile);
    else return "";
  }
  get getAroDocs() {
    return Docs.getAroDocs;
  }
  get getPsiraDocs() {
    if (this.getAroDocs != null)
      return this.getAroDocs.psiraRegistrationDocuments;
    else return [];
  }

  get getPsiraRegNo() {
    if (this.getAroDocs != null) {
      return this.getAroDocs.psiraRegistrationNumber;
    } else {
      return null;
    }
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  async init(): Promise<void> {
    if (this.getPsiraDocs.length > 0) {
      logger.warn("Init before files :");
      const res = await Promise.all([
        Documents.getFile(this.getPsiraDocs[0].filePath).catch((err) =>
          Promise.resolve(null)
        ),
        Documents.getFile(this.getPsiraDocs[0].renewedDocumentFilePath).catch(
          (err) => Promise.resolve(null)
        ),
      ]);

      const psira = res[0];
      const renewed = res[1];
      logger.warn("Init after files psira :", res);

      this.currentDoc = {
        PSIRARegistrationDocumentID: this.getPsiraDocs[0].id,
        PSIRARegistrationAROID: this.getPsiraDocs[0].armResponseOfficerID,
        PSIRARegistrationExpiryDate: dayjs(
          this.getPsiraDocs[0].expiryDate
        ).format("YYYY-MM-DD"),
        Grade: this.getPsiraDocs[0].grade,
        RegistrationNumber: String(this.getPsiraRegNo),
        RenewedDocumentFile: renewed,
        RenewedDate:
          this.getPsiraDocs[0].renewedDate != null
            ? dayjs(this.getPsiraDocs[0].renewedDate).format("YYYY-MM-DD")
            : null,
        PSIRAFile: psira,
        isRenewed: this.getPsiraDocs[0].isRenewed,
      };
      logger.log("PSIRA REG No:", this.currentDoc.RegistrationNumber);
    } else {
      this.currentDoc = {
        PSIRARegistrationDocumentID: 0,
        PSIRARegistrationAROID: 0,
        PSIRARegistrationExpiryDate: null,
        Grade: null,
        RegistrationNumber:
          this.getPsiraRegNo != null ? this.getPsiraRegNo : "",
        RenewedDocumentFile: null,
        RenewedDate: null,
        PSIRAFile: null,
        isRenewed: false,
      };
    }
  }
  @Watch("getPsiraDocs", {
    immediate: true,
  })
  async onDocsChanged(val: PsiraRegistrationDocuments[]): Promise<void> {
    //   logger.log("Docs changed ! ---", val);
    if (val) await this.init();
  }
  async mounted(): Promise<void> {
    //   logger.log("Mounted PSira Reg", this.currentDoc);
    if (this.getPsiraDocs) {
      await this.init();
    } else {
      logger.warn("No docs ");
    }
  }
}
