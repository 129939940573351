
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import {
  ArmResponseOfficer,
  Firearm,
  PsiraRegistrationDocuments,
  PsiraRegistrationDocuments_Create,
} from "models";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import FileMixin from "@/mixins/FileMixin.vue";

interface Certificate {
  armResponseOfficerID: number;
  certificateFileName: string;
  certificateFilePath: string;
  createdBy: string;
  createdDate: string;
  id: number;
  statementOfResultFileName: string;
  statementOfResultFilePath: string;
  updatedBy: string;
  updatedDate: string;
}

@Component({
  components: {
    VuePdfApp,
  },
})
export default class TacticalKnowledge extends Mixins(FileMixin) {
  @Prop() certificate!: Certificate | null;
  @Prop() aroId!: number;

  valid = false;
  certExpanded = false;
  sorExpanded = false;

  currentDoc: {
    TacticalResultFile: File | null;
    TacticalKnowledgeFile: File | null;
    TacticalKnowledgeAROID: number;
    TacticalKnowledgeID: number;
  } | null = null;

  loading = false;
  URL = URL;

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  // certPreview: null | string = null;
  // renewedPreview: null | string = null;
  errorHandler(e: any) {
    //   logger.log("Erroror", e);
  }

  async deleteFile(file: number): Promise<void> {
    try {
      this.loading = true;
      if (this.currentDoc) {
        const fileId = this.currentDoc.TacticalKnowledgeID;

        const res = await Documents.deleteTacticalKnowledge(fileId, file);
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.loading = false;
    }
  }

  get getCertPreview() {
    // return this.psiraPreview;
    if (this.currentDoc?.TacticalKnowledgeFile != null)
      return URL.createObjectURL(this.currentDoc.TacticalKnowledgeFile);
    else return "";
  }
  get getStatementPreview() {
    // return this.psiraPreview;
    if (this.currentDoc?.TacticalResultFile != null)
      return URL.createObjectURL(this.currentDoc.TacticalResultFile);
    else return "";
  }
  async init(): Promise<void> {
    if (this.certificate != null) {
      const res = await Promise.all([
        Documents.getFile(this.certificate.certificateFilePath),
        Documents.getFile(this.certificate.statementOfResultFilePath),
      ]);
      this.currentDoc = {
        TacticalKnowledgeFile: res[0],
        TacticalResultFile: res[1],
        TacticalKnowledgeAROID: this.aroId,
        TacticalKnowledgeID: this.certificate.id,
      };
    } else {
      this.currentDoc = {
        TacticalKnowledgeAROID: this.aroId,
        TacticalResultFile: null,
        TacticalKnowledgeFile: null,
        TacticalKnowledgeID: 0,
      };
    }
  }
  async mounted(): Promise<void> {
    //   logger.log("Mounted Saps COmpenecye", this.certificate);
    await this.init();
  }
  async addTacticalKnowledge(): Promise<void> {
    try {
      this.loading = true;

      const formData = new FormData();
      if (this.currentDoc != null) {
        Object.entries(this.currentDoc).forEach(([key, value]) => {
          if (value != null) {
            if (typeof value === "number") {
              formData.append(key, value.toString());
            } else {
              formData.append(key, value);
            }
          }
        });
      }

      //   logger.log("Form data ", formData);
      const res = await axiosInstance.post(
        "/api/ManageDocumentsAPI/addTacticalKnowledge",
        formData
      );
      this.$emit("refresh");
      //   logger.log("Add Tactical Knowledge response", res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  @Watch("certificate", {
    immediate: true,
  })
  async onCertChanged(
    val: string | File | null,
    oldVal: string | File | null
  ): Promise<void> {
    //   logger.log("Sor changed from ", oldVal, " to ", val);
    await this.init();
  }
}
