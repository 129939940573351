
import { OldPermit, AmmunitionReturn } from "models";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Permits } from "@/hooks";
import Auth from "@/store/modules/Auth";
import Signature from "@/components/Signature.vue";
import logger from "@/plugins/logger";

@Component({
  components: {
    Signature,
  },
})
export default class ReturnPermit extends Vue {
  @Prop() readonly open!: boolean;
  @Prop() readonly permit!: OldPermit;
  validReturnPermit = false;
  returnAmount: number | null = null;
  remarks = "";
  confirmFirearm = false;
  loading = false;
  returnSignature: string | null = null;
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    negvalcheck: (value: number): boolean | string =>
      value >= 0 || "Can't be less than 0",
  };

  @Watch("open")
  modalOpened(): void {
    if (this.open == true) {
      this.returnAmount = this.permit.ammunitionCount;
    }
  }

  close(): void {
    this.validReturnPermit = false;
    this.returnAmount = null;
    this.remarks = "";
    this.confirmFirearm = false;
    this.$emit("close");
  }
  reload(): void {
    this.$emit("reload");
  }

  get getEnforcedSignature(): boolean {
    if (Auth.getUserOverview) {
      if (
        Auth.getUserOverview.userSignatureID == null ||
        Auth.getUserOverview.userSignatureID == undefined
      ) {
        return true;
      } else {
        return Auth.getUserOverview.securityFirm.enforceSignatures;
      }
    } else {
      return false;
    }
  }

  get getSignaturePreview(): string | null {
    /* `data:${signature.mimeType};base64,${signature.dataFiles}` */
    if (Auth.getUserOverview && Auth.getUserOverview.userSignature) {
      return `data:${Auth.getUserOverview.userSignature.mimeType};base64,${Auth.getUserOverview.userSignature.dataFiles}`;
    } else {
      return null;
    }
  }
  handleSignatureUpdate(b64: string) {
    logger.log("Signature: ", b64);
    if (b64 != undefined) {
      this.returnSignature = b64;
    }
  }
  async submitPermitReturn(): Promise<void> {
    try {
      if (this.returnAmount == null) return Promise.reject("No amount");
      this.loading = true;
      const body: AmmunitionReturn = {
        assignFirearmID: this.permit.id,
        calibre: this.permit.firearm.calibre,
        totalAllocated: this.permit.ammunitionCount,
        returnQuantity: this.returnAmount,
        remarks: this.remarks,
      };
      if (this.getEnforcedSignature == true && this.returnSignature != null) {
        body.returnedBySignature = {
          fileName: "",
          fileSize: 0,
          mimeType: "",
          upload: this.returnSignature,
        };
      }
      const res = await Permits.returnPermit(body);
      this.reload();
      this.close();
      // logger.log("Permit returned: ", res);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
